import React from "react";
import '../../App.css';

export default function FooterDesktop() {
  
    return (
      <div
        className="footerDektopHolder"
      ></div>
    );
  }
